/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Container, Flex, Heading, jsx } from 'theme-ui'
import ProductList from '~/components/Product/List'

const CollectionList = ({ collection }) => {
  const { title, description, products } = collection
  return (
    <Container
      sx={{
        borderTop: ['1px solid', 'none'],
        maxWidth: '1440px',
        paddingTop: ['12px', '0', null]
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          borderTop: ['none', '1px solid'],
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          padding: ['16px 24px', null, '32px 0'],
          width: '100%'
        }}
      >
        <Heading
          as="h2"
          sx={{
            lineHeight: '1em',
            paddingRight: [null, '5rem'],
            textAlign: ['center', 'left'],
            marginBottom: ['8px', 0, null]
          }}
        >
          {title}
        </Heading>
        {description && (
          <Box
            sx={{
              textAlign: ['center', 'right'],
              '& p': {
                margin: 0
              }
            }}
            variant="text.subhead"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        )}
      </Flex>
      {products && <ProductList products={products} />}
    </Container>
  )
}

CollectionList.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        mainImage: PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
          })
        )
      })
    ),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default CollectionList
