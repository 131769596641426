import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductListPage from '~/components/Product/ListPage'

const Shop = ({ data }) => {
  const { page, catalog } = data

  return (
    <Layout>
      <Metadata title={page.name} />
      <ProductListPage page={page} catalog={catalog} />
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopPage($locale: String) {
    catalog: contentfulCatalog(
      slug: { eq: "catalog" }
      node_locale: { eq: $locale }
    ) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(slug: { eq: "shop" }, node_locale: { eq: $locale }) {
      name
      ...ContentHeroFragment
    }
  }
`
