/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import Chevron from '~/assets/images/icons/chevron.svg'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const CollectionFilterBarTitle = ({
  onClick,
  currentCollection,
  menuOpen = false,
  sx,
  ...props
}) => {
  const translate = useTranslate()

  return (
    <Flex
      onClick={onClick}
      sx={{
        display: [null, 'none'],
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        padding: ['20px 20px', 0],
        ...sx
      }}
      {...props}
    >
      {currentCollection && !menuOpen
        ? currentCollection.title
        : translate('product.filter_collections')}
      <Chevron />
    </Flex>
  )
}

CollectionFilterBarTitle.propTypes = {
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    title: PropTypes.string
  }),
  menuOpen: PropTypes.bool
}

export default CollectionFilterBarTitle
